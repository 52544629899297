import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './table.scss';

const List = () => {

    const rows = [
        {
            id: 1143155,
            product: "Acer Nitro 5",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
            customer: "John Smith",
            date: "1 March",
            amount: 785,
            method: "Cash on Delivery",
            status: "Approved"
        },
        {
            id: 1143122,
            product: "Acer Nitro 5",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
            customer: "John Smith",
            date: "1 March",
            amount: 785,
            method: "Cash on Delivery",
            status: "Approved"
        },
        {
            id: 1143555,
            product: "Acer Nitro 5",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
            customer: "John Smith",
            date: "1 March",
            amount: 785,
            method: "Cash on Delivery",
            status: "Pending"
        },
        {
            id: 2254611,
            product: "HP X",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
            customer: "John Smith",
            date: "22 April",
            amount: 4211,
            method: "Cash on Delivery",
            status: "Approved"
        },
        {
            id: 3421235,
            product: "HP X",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
            customer: "John Smith",
            date: "20 August",
            amount: 3312,
            method: "Cash on Delivery",
            status: "Approved"
        }
    ];

    return (
            <TableContainer component={Paper} className="table">
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">Tracking ID</TableCell>
                            <TableCell className="tableCell">Product</TableCell>
                            <TableCell className="tableCell">Customer</TableCell>
                            <TableCell className="tableCell">Date</TableCell>
                            <TableCell className="tableCell">Amount</TableCell>
                            <TableCell className="tableCell">Payment Method</TableCell>
                            <TableCell className="tableCell"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell className="tableCell">
                                        <div className="cellWrapper">
                                            <img src={row.img} alt="" className="image"/>
                                            {row.product}
                                        </div>
                                    </TableCell>
                                    <TableCell className="tableCell">{row.customer}</TableCell>
                                    <TableCell className="tableCell">{row.date}</TableCell>
                                    <TableCell className="tableCell">{row.amount}</TableCell>
                                    <TableCell className="tableCell">{row.method}</TableCell>
                                    <TableCell className="tableCell">
                                        <span className={`status ${row.status}`}>{row.status}</span>
                                    </TableCell>
                                </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            );
};

export default List
