import React from 'react';
import ReactDOM from 'react-dom/client';
import './about.scss';

const About = () => {
    return (
        <div className="about">
            <h1 className="title">About</h1>
        </div>
    );
};

export default About
