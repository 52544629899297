import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link} from 'react-router-dom';
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';

const Sidebar = () => {
    const { dispatch } = useContext(DarkModeContext);
    
    return (
            <div className="sidebar">
                <div className="top">
                    <Link to="/" style={{textDecoration: "none"}}>
                    <span className="logo"> L<span className="logoDesktop">ogo</span> </span>
                    </Link>
                </div>
                <div className="center">
                    <ul>
                        <p className="title">MAIN</p>
                        <li>
                        <DashboardIcon className="icon"/>
                        <span className="listTitle">Dashboard</span>
                        </li>
                        <p className="title">LISTS</p>
                        <Link to="/users" style={{textDecoration: "none"}}>
                            <li>
                                <PersonIcon className="icon"/>
                                <span className="listTitle">Users</span>
                            </li>
                        </Link>
                        <Link to="/products" style={{textDecoration: "none"}}>
                            <li>
                                <InventoryIcon className="icon"/>
                                <span className="listTitle">Products</span>
                            </li>
                        </Link>
                        <li>
                        <CreditCardIcon className="icon"/>
                        <span className="listTitle">Orders</span>
                        </li>
                        <li>
                        <LocalShippingIcon className="icon"/>
                        <span className="listTitle">Delivery</span>
                        </li>
                        <p className="title">USEFUL</p>
                        <li>
                        <AssessmentIcon className="icon"/>
                        <span className="listTitle">Stats</span>
                        </li>
                        <li>
                        <NotificationsNoneIcon className="icon"/>
                        <span className="listTitle">Notifications</span>
                        </li>
                        <p className="title">SERVICE</p>
                        <li>
                        <SettingsSystemDaydreamIcon className="icon"/>
                        <span className="listTitle">System Health</span>
                        </li>
                        <li>
                        <PsychologyIcon className="icon"/>
                        <span className="listTitle">Logs</span>
                        </li>
                        <li>
                        <SettingsIcon className="icon"/>
                        <span className="listTitle">Settings</span>
                        </li>
                        <p className="title">USER</p>
                        <li>
                        <AccountBoxIcon className="icon"/>
                        <span className="listTitle">Profile</span>
                        </li>
                        <li>
                        <LogoutIcon className="icon"/>
                        <span className="listTitle">Logout</span>
                        </li>
                    </ul>
                </div>
                <div className="bottom">
                    <p className="title">Template Mode</p>
                    <div className="modes">
                        <div className="colorOption" onClick={() => dispatch({type: "LIGHT"})}></div>
                        <div className="colorOption" onClick={() => dispatch({type: "DARK"})}></div>
                    </div>
                </div>
            </div>
            );
};

export default Sidebar;