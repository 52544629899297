import React from 'react';
import ReactDOM from 'react-dom/client';
import './contacts.scss';

const Contacts = () => {
    return (
        <div className="contacts">
            <h1 className="title">Contacts</h1>
        </div>
    );
};

export default Contacts
