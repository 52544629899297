import './navbar.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';

const Navbar = () => {
    const { dispatch } = useContext(DarkModeContext);
    console.log(DarkModeContext._currentValue.darkMode);
    return (
            <div className="navbar">
                <div className="wrapper">
                    <div className="search">
                        <input type="text" placeholder="Search ..."/>
                        <SearchOutlinedIcon/>
                    </div>
                    <div className="items">
                        <div className="item">
                            <LanguageOutlinedIcon className="icon"/>
                            English
                        </div>
                        <div className="item">
                        {DarkModeContext._currentValue.darkMode
                            ? <LightModeOutlinedIcon className="icon" style={{ cursor: "pointer" }} onClick={() => dispatch({type: "TOGGLE"})}/>
                            : <DarkModeOutlinedIcon className="icon" style={{ cursor: "pointer" }} onClick={() => dispatch({type: "TOGGLE"})}/>
                        }
                        </div>
                        <div className="item">
                            <FullscreenExitOutlinedIcon className="icon"/>
                        </div>
                        <div className="item">
                            <NotificationsOutlinedIcon className="icon"/>
                            <div className="counter">1</div>
                        </div>
                        <div className="item">
                            <ChatBubbleOutlineOutlinedIcon className="icon"/>
                            <div className="counter">1</div>
                        </div>
                        <div className="item">
                            <ListOutlinedIcon/>
                        </div>
                        <div className="item">
                            <img src="https://thumbs.dreamstime.com/b/happy-smiling-geek-hipster-beard-man-cool-avatar-geek-man-avatar-104871313.jpg"
                            className="avatar"/>
                        </div>
                    </div>
                </div>
            </div>
            );
};

export default Navbar;