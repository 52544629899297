import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import logo from './logo.svg';
import './App.scss';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contacts from './pages/contacts/Contacts';
import List from './pages/list/List';
import Single from './pages/single/Single';
import ListItemEdit from './pages/list/edit/ListItemEdit';
import New from './pages/new/New';
import { userInputs, productInputs } from './formSource';
import './style/dark.scss';
import { useContext } from 'react';
import { DarkModeContext } from "./context/darkModeContext";

function App() {
    
const { darkMode } = useContext(DarkModeContext);
    
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
            <Route path="/">
                <Route index element={<Home/>}></Route>
                <Route path="about" element={<About/>}></Route>
                <Route path="contacts" element={<Contacts/>}></Route>
                <Route path="users">
                    <Route index element={<List/>}/>
                    <Route path=":id" element={<Single/>}/>
                    <Route path="new" element={<New inputs = {userInputs} title="Add New User" />}/>
                </Route>
                <Route path="products">
                    <Route index element={<List/>}/>
                    <Route path=":id" element={<Single/>}/>
                    <Route path="new" element={<New inputs = {productInputs} title="Add New Product" />}/>
                </Route>
            </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
