export const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
        field: "user",
        headerName: "User",
        width: 230,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    <img className="cellImg" src={params.row.img} alt="avatar" />
                    { params.row.username }
                </div>
            );
        }
    },
    { field: "email", headerName: "Email", width: 230 },
    { field: "age", headerName: "Age", width: 100 },
    {
        field: "status",
        headerName: "Status",
        width: 160,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>
            );
        }
    },
];
export const userRows = [
    {
        id: 1,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "active",
        email: "1snow@gmail.com",
        age: 22
    },
    {
        id: 2,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "active",
        email: "1snow@gmail.com",
        age: 27
    },
    {
        id: 3,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "passive",
        email: "1snow@gmail.com",
        age: 31
    },
    {
        id: 4,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 5,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 6,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 7,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 8,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 9,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    },
    {
        id: 10,
        username: "Snow",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png",
        status: "pending",
        email: "1snow@gmail.com",
        age: 18
    }
];